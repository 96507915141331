export interface Tax {
  taxSign: string;
  tax: number;
  text: string;
}
export const taxSignsSap = [
  {
    taxSign: 'V2',
    tax: 20,
    text: 'Vorsteuer 20%',
  },
  {
    taxSign: 'B0',
    tax: 0,
    text: 'Aufw. §19/1a Bauleistungen o. VSt-Abzug',
  },
  {
    taxSign: 'B1',
    tax: 10,
    text: 'Aufw. §19/1a Bauleistungen m. VSt-Abzug 10%',
  },
  {
    taxSign: 'B2',
    tax: 0,
    text: 'Aufw. §19/1a Bauleistungen m. VSt-Abzug 20%',
  },
  {
    taxSign: 'BL',
    tax: 0,
    text: 'Bauleistungen Umsatz 0%',
  },
  {
    taxSign: 'D0',
    tax: 0,
    text: 'Umsätze grenzüberschreitende Dienstleistungen BSB',
  },
  {
    taxSign: 'D2',
    tax: 0,
    text: 'Umsätze grenzüberschreitende Dienstleistungen BSC',
  },
  {
    taxSign: 'DB',
    tax: 0,
    text: 'Bauleistung Deutschland §13b UStG',
  },
  {
    taxSign: 'E0',
    tax: 0,
    text: 'Aufwand ig. Erwerb ohne VSt-Abzug',
  },
  {
    taxSign: 'E1',
    tax: 0,
    text: 'Aufwand ig. Erwerb 10%',
  },
  {
    taxSign: 'E2',
    tax: 0,
    text: 'Aufwand ig. Erwerb 20%',
  },
  {
    taxSign: 'E3',
    tax: 0,
    text: 'Aufwand ig. Erwerb 19%',
  },
  {
    taxSign: 'EF',
    tax: 0,
    text: 'EUST FA §12 Abs. 2 b)',
  },
  {
    taxSign: 'EG',
    tax: 0,
    text: 'Eigenverbrauch',
  },
  {
    taxSign: 'EU',
    tax: 0,
    text: 'EUST Zoll §12 Abs. 2 a)',
  },
  {
    taxSign: 'I0',
    tax: 0,
    text: 'innergemeinschaftliche Lieferung',
  },
  {
    taxSign: 'ID',
    tax: 0,
    text: 'innergemeinschaftliche Lieferung Deutschland',
  },
  {
    taxSign: 'NZ',
    tax: 0,
    text: 'nicht steuerbar',
  },
  {
    taxSign: 'RM',
    tax: 0,
    text: '',
  },
  {
    taxSign: 'R0',
    tax: 0,
    text: 'Aufw. §19/1 Rerverse Charge o. VSt-Abzug',
  },
  {
    taxSign: 'R1',
    tax: 0,
    text: 'Aufw. §19/1 Rerverse Charge m. VSt-Abzug 10%',
  },
  {
    taxSign: 'R2',
    tax: 0,
    text: 'Aufw. §19/1 Rerverse Charge m. VSt-Abzug 20%',
  },
  {
    taxSign: 'RD',
    tax: 0,
    text: 'Reverse Charge Deutschland 19%',
  },
  {
    taxSign: 'RL',
    tax: 0,
    text: 'Reverse Charge 20% PKW-Leasing ohne Vs',
  },
  {
    taxSign: 'SL',
    tax: 0,
    text: 'Schrottverkauf Umsatz 0%',
  },
  {
    taxSign: 'U0',
    tax: 0,
    text: 'Umsatzsteuer 0%',
  },
  {
    taxSign: 'U1',
    tax: 10,
    text: 'Umsatzsteuer 10%',
  },
  {
    taxSign: 'U2',
    tax: 20,
    text: 'Umsatzsteuer 20%',
  },
  {
    taxSign: 'U7',
    tax: 0,
    text: 'Umsatzsteuer 7% DE',
  },
  {
    taxSign: 'UD',
    tax: 0,
    text: 'Umsatzsteuer 19% BRD',
  },
  {
    taxSign: 'UG',
    tax: 0,
    text: 'Umsatzsteuer 20% GB',
  },
  {
    taxSign: 'V0',
    tax: 0,
    text: 'Vorsteuer 0%',
  },
  {
    taxSign: 'V1',
    tax: 10,
    text: 'Vorsteuer 10%',
  },
  {
    taxSign: 'V3',
    tax: 12,
    text: 'Vorsteuer 12%',
  },
  {
    taxSign: 'V4',
    tax: 13,
    text: 'Vorsteuer 13%',
  },
  {
    taxSign: 'V5',
    tax: 5,
    text: 'Vorsteuer 5%',
  },
  {
    taxSign: 'V6',
    tax: 100,
    text: 'Vorsteuer 100%',
  },
  {
    taxSign: 'V7',
    tax: 0,
    text: 'Vorsteuer 7% BRD',
  },
  {
    taxSign: 'V9',
    tax: 0,
    text: 'Vorsteuer 0% Einfuhr-Drittländer',
  },
  {
    taxSign: 'VD',
    tax: 0,
    text: 'Vorsteuer 19% BRD',
  },
  {
    taxSign: 'VG',
    tax: 0,
    text: 'Vorsteuer 20% GB',
  },
  {
    taxSign: 'VK',
    tax: 20,
    text: 'Vorsteuer KFZ 20%',
  },
  {
    taxSign: 'Keine Steuer',
    tax: 0,
    text: '',
  },
];
